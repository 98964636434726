import './App.css';
import {useRive, useStateMachineInput} from 'rive-react';
import pot from "./assets/pot.riv";
import { useState, useEffect } from 'react';

function App() {

    const { rive, RiveComponent } = useRive({
        src: pot,
        autoplay: true,
        stateMachines: "State Machine 1",
        animations: "Timeline 1"
    });

    const onClickInput = useStateMachineInput(rive, "State Machine 1", "eusunt")

    const [questionIndex, setQuestionIndex] = useState(0);
    const [answer, setAnswer] = useState('');

    const questions = [
        "Question 1: E pistol un smecher? (yes/no)",
        "Question 2: E un pui de bagabont? (yes/no)",
        "Question 3: Are schema completa de vaccinuri? (yes/no)"
    ];

    const correctAnswers = ["yes", "no", "yes"];

    const handleAnswer = (event) => {
        event.preventDefault();
        if (answer.toLowerCase() === correctAnswers[questionIndex]) {
            onClickInput.fire();
        } else {
            onClickInput.fire();
        }
        setQuestionIndex(questionIndex + 1);
        setAnswer('');
    }

    useEffect(() => {
        if(rive === null) return;
    }, [rive, onClickInput]);

    return (
        <div className="App">
            <RiveComponent
                onClick={ () => onClickInput && onClickInput.fire()}
            />
            {questionIndex < questions.length && (
                <form onSubmit={handleAnswer}>
                    <label>
                        {questions[questionIndex]}
                        <input type="text" value={answer} onChange={e => setAnswer(e.target.value)} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            )}
        </div>
    );
}

export default App;